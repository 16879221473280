import styled from 'styled-components';

export type AspectRatio = '16x9' | '21x9' | '4x3' | '1x1';

export default {
  Container: styled.div<{ aspectRatio?: AspectRatio; scale?: string }>`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    width: 100%;

    ${props => props.aspectRatio == '21x9' && 'padding-top: 0px; padding-bottom: 42.857143%;'}
    ${props => props.aspectRatio == '16x9' && 'padding-top: 0px; padding-bottom: 56.25%;'}
    ${props => props.aspectRatio == '4x3' && 'padding-top: 0px; padding-bottom: 75%;'}
    ${props => props.aspectRatio == '1x1' && 'padding-top: 0px; padding-bottom: 100%;'}

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100% !important;
      ${props => props.scale && `transform: scale(${props.scale});`}
    }
  `
};
