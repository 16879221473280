import React, { useState, useEffect } from 'react';
import Flex from '../../components/Flex';
import SVGIcon from '../../components/SVGIcon';
import Text from '../../components/Text';
import LocalizedText from 'src/components/LocalizedText';
import DashboardLoader from './styles';

const loadingMessages = ['screenDashboardAndLogging', 'screen2', 'screen3', 'screen4', 'screen5', 'screen6'];

const loadingStatesMessage = () => {
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    let timeout: any = null;
    if (messageIndex < loadingMessages.length - 1) {
      timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 4000);
    }
    return () => clearTimeout(timeout);
  }, [loadingMessages, messageIndex]);

  return `loading.${loadingMessages[messageIndex]}`;
};

export const UserLoader = () => {
  return (
    <DashboardLoader.Container>
      <Flex justifyContent="space-around" alignSelf="center" alignItems="center" mobile fullWidth>
        <DashboardLoader.Icon>
          <SVGIcon height="65px" src="/static/icons/dashboard-loader.svg" paddingLeft="none" />
        </DashboardLoader.Icon>
        <DashboardLoader.Text>
          <Text.H6 weight="bold">
            <LocalizedText id={loadingStatesMessage()} description="Loading" />
          </Text.H6>
        </DashboardLoader.Text>
      </Flex>
    </DashboardLoader.Container>
  );
};

export default React.memo(UserLoader);
