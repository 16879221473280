import React from 'react';
import truncate from 'lodash/truncate';
import Head from 'next/head';
import { useIntl } from 'react-intl';

const StandardHead = (props: {
  image?: string | undefined;
  title?: string;
  description?: string;
  url?: string;
  twitter_card?: string;
  customTabTitleKey?: string;
}) => {
  const intl = useIntl();

  return (
    <Head>
      <meta property="og:type" key="og:type" content="article" />
      <meta property="og:title" key="og:title" content={truncate(props.title, { length: 95 })} />
      <meta property="og:description" key="og:description" content={props.description} />
      <meta property="og:url" key="og:url" content={props.url} />

      <meta name="twitter:card" key="twitter:card" content={props.twitter_card} />
      <meta name="twitter:title" key="twitter:title" content={truncate(props.title, { length: 70 })} />
      <meta name="twitter:url" key="twitter:url" content={props.url} />
      <meta name="twitter:description" key="twitter:description" content={props.description} />
      <meta name="description" content={intl.formatMessage({ id: 'meta.og.description.default' })}></meta>
      {props.image && (
        <>
          <meta property="og:image" key="og:image" content={props.image} />
          <meta name="twitter:image" key="twitter:image" content={props.image} />
        </>
      )}
      <title>{`${intl.formatMessage({ id: props.customTabTitleKey || 'title' })} | ${intl.formatMessage({
        id: 'sge'
      })}`}</title>
    </Head>
  );
};

export default StandardHead;
