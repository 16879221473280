import React, { ReactElement, useEffect, useState } from 'react';
import styles from './styles';
import Flex from '../../components/Flex';
import TextIcon from '../TextIcon';
import {
  registeronViewChangeFunction,
  showLock,
  viewType,
  loadingStateType,
  registerOnLoadingStateChangeFunction
} from 'src/utils/auth0Utils';
import Spinner from 'src/components/Spinner';
import { useBreakpoints } from 'src/utils/viewportUtils';
import Spacing from 'src/components/Spacing';
import { useLocale } from 'src/locales';
import { useIntl } from 'react-intl';
import LocalizedText from '../LocalizedText';
import { redirectUrl2serviceName, servicesContent } from './dynamicContentLogic';
import UserLoader from '../UserLoader';
import VideoFrame from '../VideoFrame';
import { waitForDOMElement, waitForDOMElementAbsence } from 'src/utils/reactUtils';
import { AspectRatio } from 'src/components/Iframe';
import getConfig from 'next/config';
import { AppLink, LanguageSelector } from '@components';

type SignUpProps = React.PropsWithChildren<{
  bgImageSrc?: string;
  slug?: string;
  redirectUrl?: string;
  redirecting?: boolean;
  syncing?: boolean;
  children: React.ReactNode;
  ccgid?: string;
}>;

const LinkArrow = (props: {
  text: string;
  href: string;
  onClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}) => {
  return (
    <Flex>
      <styles.ActionLink onClick={props.onClick} href={props.href}>
        <TextIcon
          shouldUnderlineOnHover
          variant="Tiny"
          size="16px"
          paddingLeft="xsm"
          weight="bold"
          color="onWhitePrimary"
          spacing="none"
          rightIconComponent={() => <img alt="action arrow" src="/static/icons/semiArrowRightTinyPrimary.svg" />}
        >
          <LocalizedText id={props.text} />
        </TextIcon>
      </styles.ActionLink>
    </Flex>
  );
};

type ContentDefinition = {
  tag: string;
  content: string;
  link?: string;
  aspectRatio?: AspectRatio;
  scale?: string;
};

const JoinLogin: React.FC<SignUpProps> = ({ slug, redirectUrl, redirecting, syncing, ccgid, children }) => {
  const [state, setState] = useState(slug);
  const [loadingState, setLoadingState] = useState('');
  const [hidden, setHidden] = useState(true);
  const [limitedSyncing, setLimitedSyncing] = useState(syncing);
  const { custom1: customMobile, custom2: customMobileA } = useBreakpoints(1146, 800);
  const intl = useIntl();
  const locale = useLocale();
  const [wrongRedirectUrl, setWrongRedirectUrl] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  const { publicRuntimeConfig } = getConfig();
  const websiteUrl = `${publicRuntimeConfig.SGE_WEBSITE_URL || 'https://test.s-ge.com'}/${locale}`;

  useEffect(() => {
    //Redirecting in case redirect url is wrong
    const currentServiceName = redirectUrl2serviceName(redirectUrl, locale);
    if (currentServiceName == 'WrongRedirectUrl') {
      setWrongRedirectUrl(true);
      window.location.assign(`/login`);
      return;
    }

    //In very rare cases it happens that syncing is never set to false.
    //To avoid infinitive load animation the 10sec timeout is used to show login form
    setTimeout(() => {
      setLimitedSyncing(false);
    }, 10000);

    registeronViewChangeFunction(onViewChange);
    registerOnLoadingStateChangeFunction(onLoadingStateChange);

    const root = document.querySelector('#inline-login-container');
    let auth0LockSpiner = false;

    if (root) {
      waitForDOMElement('div.auth0-lock .auth0-lock-view-content', root).then(() => {
        setTimeout(() => {
          if (!auth0LockSpiner) {
            setHidden(false);
          }
        }, 0);
      });

      // Sometimes auth0 lock shows additional preloader that causes an extra delay.
      // The code below is to handle that and show application preloader instead.
      waitForDOMElement('.auth0-loading-screen', root).then(() => {
        auth0LockSpiner = true;
        setHidden(true);
        waitForDOMElementAbsence('.auth0-loading-screen').then(() => {
          auth0LockSpiner = false;
          setTimeout(() => {
            setHidden(false);
          }, 800);
        });
      });
    }
  }, []);

  const onLoadingStateChange = (loadingState: loadingStateType) => {
    setLoadingState(loadingState);
  };

  const onViewChange = (newView: viewType) => {
    const ccgdiPart = ccgid ? '?ccgid=' + ccgid : '';

    if (newView == 'resetpassword') {
      window.history.pushState('resetpassword', 'Reset password', `/${locale}/resetpassword${ccgdiPart}`);
      setState('/resetpassword');
    }

    if (newView == 'join') {
      window.history.pushState('join', 'Join', `/${locale}/join${ccgdiPart}`);
      setState('/join');
    }

    if (newView == 'login') {
      window.history.pushState('login', 'Login', `/${locale}/login${ccgdiPart}`);
      setState('/login');
    }

    if (newView == 'authentication-in-progress') {
      setState('authentication-in-progress');
    }
  };

  const currentServiceName = redirectUrl2serviceName(redirectUrl, locale);

  const dynamicContent = servicesContent[currentServiceName];
  const icon = dynamicContent?.icon;
  const text = (dynamicContent && intl.formatMessage({ id: dynamicContent?.translationId })) || '';

  const dynamicElements: ContentDefinition[] = text.split('\n').map((line: string) => {
    const parts = line.split('|');
    const tag = parts[0];
    const content = parts[1];
    return {
      tag,
      content,
      ...(parts.length == 3 && tag == 'inline-small-link' ? { link: parts[2] } : {}),
      ...(parts.length >= 3 && (tag == 'video' || tag == 'ytvideo') && parts[2].indexOf('x') !== -1
        ? { aspectRatio: parts[2].trim() }
        : {}),
      ...(parts.length >= 3 && (tag == 'video' || tag == 'ytvideo') && parts[2].indexOf('x') === -1
        ? { scale: parts[2].trim() }
        : {}),
      ...(parts.length == 4 && (tag == 'video' || tag == 'ytvideo') ? { scale: parts[3].trim() } : {})
    };
  });

  const dynamicElementsTop: ReactElement[] = [];
  const dynamicElementsMore: ReactElement[] = [];
  let wasTitle = false;
  let wasSubtitle = false;

  const hasSubtitle = dynamicElements.filter(element => element?.tag === 'sub-title').length > 0;

  /*  
  Example:
  title|Service context relevant tagline on 2 lines DB
  sub-title|Clearly convey the value of service. Maxiumum 1 line.
  point|Clearly convey the value explanation on maxiumum 2 lines
  point|Another advantage highlight on maxium 2 lines
  point|Yet another advantage highlight on maxium 2 lines
  image|/static/img/login-join-img-default.jpg
  ytvideo|C0DPdy98e4c|16x4|1.01
  video|https://www.youtube.com/embed/C0DPdy98e4c|16x4|1.01      //video|link|aspectRatio|scale
  inline-small-text|...or discover all benefits
  inline-small-link|on this webpage|https://www.google.pl
 */
  dynamicElements.map((element, index) => {
    (wasTitle && wasSubtitle ? dynamicElementsMore : dynamicElementsTop).push(
      <React.Fragment key={index}>
        {element.tag == 'title' && (
          <Spacing key={index} paddingBottom={hasSubtitle ? undefined : 'md'}>
            <styles.InfoTitle>{element.content}</styles.InfoTitle>
          </Spacing>
        )}
        {element.tag == 'sub-title' && <styles.InfoSubTitle key={index}>{element.content}</styles.InfoSubTitle>}
        {element.tag == 'point' && (
          <styles.InfoListText vertical={false} key={index}>
            <div>
              <img alt="benefit" src="/static/icons/checkCircle.svg" />
            </div>
            <div>{element.content}</div>
          </styles.InfoListText>
        )}
        {element.tag == 'image' && (
          <styles.InfoImage alt="service" key={index} src={element.content}></styles.InfoImage>
        )}
        {element.tag == 'ytvideo' && (
          <styles.InfoVideo>
            <VideoFrame
              aspectRatio={element.aspectRatio}
              scale={element.scale}
              url={`https://www.youtube.com/embed/${element.content}`}
            />
          </styles.InfoVideo>
        )}
        {element.tag == 'video' && (
          <styles.InfoVideo key={index}>
            <VideoFrame aspectRatio={element.aspectRatio} scale={element.scale} url={element.content} />
          </styles.InfoVideo>
        )}
        {element.tag == 'inline-small-text' && (
          <styles.InfoInlineSmallText key={index}>{element.content}</styles.InfoInlineSmallText>
        )}
        {element.tag == 'inline-small-link' && (
          <styles.InfoInlineSmallLink target="_blank" data-analytics-id="130-benefits" key={index} href={element.link}>
            {element.content}
          </styles.InfoInlineSmallLink>
        )}
      </React.Fragment>
    );

    if (element.tag == 'title') {
      wasTitle = true;
    }

    if (element.tag == 'sub-title' || !hasSubtitle) {
      wasSubtitle = true;
    }
  });

  const neededTranslations = [
    'signup.input.firstName.label',
    'signup.input.lastName.label',
    'signupLogin.input.email.label',
    'signup.input.choosePassword.label',
    'forgotPassword.back',
    'forgotPassword.sendEmailButton',
    'signup.button.createYourCockpit',
    'login.button.goToYourCockpit',
    'login.input.password.label',
    'signup.changeUserLink',
    'signup.orContainerText',
    'signup.pageTabTitle',
    'login.pageTabTitle',
    'forgotPassword.pageTabTitle',
    'sge'
  ];

  const squareLoaderShown = limitedSyncing || state == 'authentication-in-progress';

  return (
    (!wrongRedirectUrl && (
      <styles.Container>
        {children}
        <div className="translations" style={{ display: 'none' }}>
          {neededTranslations.map((translationKey, index) => (
            <div className="translation" data-translation-key={translationKey} key={index}>
              {intl.formatMessage({ id: translationKey }, { email: '{email}' })}
            </div>
          ))}
        </div>
        <Flex fullWidth vertical={customMobile}>
          <styles.InfoContainer alignItems="center" fullWidth={customMobile} vertical>
            <styles.InfoContainerContent fullWidth={false}>
              <Spacing
                paddingHorizontal={customMobile ? (customMobileA ? 'md' : undefined) : 'xxlg'}
                paddingBottom="xxlg"
                paddingTop={customMobile ? 'xlg' : 'xxxxlg'}
                marginTop={customMobile ? 'md' : undefined}
              >
                {dynamicElementsTop.map(element => {
                  return element;
                })}

                {dynamicElementsMore.length > 0 && customMobile && (
                  <Flex
                    onClick={() => {
                      setExpanded(!isExpanded);
                    }}
                  >
                    {isExpanded ? (
                      <styles.InfoShowMoreText marginBottom="lg">
                        <LocalizedText id="signupLogin.showLessButton" />
                        <img alt="show less" src="/static/icons/semiArrowUpTiny.svg" />
                      </styles.InfoShowMoreText>
                    ) : (
                      <styles.InfoShowMoreText>
                        <LocalizedText id="signupLogin.showMoreButton" />
                        <img alt="show more" src="/static/icons/semiArrowDownTiny.svg" />
                      </styles.InfoShowMoreText>
                    )}
                  </Flex>
                )}

                {(isExpanded || !customMobile) &&
                  dynamicElementsMore &&
                  dynamicElementsMore.map(element => {
                    return element;
                  })}
              </Spacing>
            </styles.InfoContainerContent>
          </styles.InfoContainer>
          <styles.Content>
            <styles.IconCircle
              scale={icon?.scale}
              marginTop={customMobile ? 'none' : 'xxxxlg'}
              justifyContent="center"
              alignItems="center"
            >
              <img src={icon?.src} />
            </styles.IconCircle>

            <styles.AuttSection
              padding="md"
              paddingTop={customMobile ? (squareLoaderShown ? 'none' : 'xlg') : 'xlg'}
              marginTop={customMobile ? 'md' : undefined}
              paddingBottom="xxxlg"
              vertical
            >
              <Flex fullWidth alignItems="center" vertical justifyContent="space-between">
                <styles.AuthBox vertical>
                  <styles.TopBar fullWidth={false} marginBottom="xmd">
                    <Flex
                      paddingHorizontal={customMobile ? '12px' : undefined}
                      alignItems="center"
                      justifyContent="space-between"
                      fullWidth
                    >
                      <LanguageSelector />
                      <AppLink href={websiteUrl} external>
                        <img alt="SGE logo" src="/static/icons/sgeLogo.svg" height="48px" />
                      </AppLink>
                    </Flex>
                  </styles.TopBar>
                  {squareLoaderShown ? (
                    <UserLoader />
                  ) : (
                    (hidden || redirecting || loadingState == 'loading-content') && (
                      <Spinner.Default marginTop="xxxlg" size="60px" color="onWhiteSecondary" />
                    )
                  )}
                  <styles.AuthBoxMainContent
                    visible={!hidden && !redirecting && !squareLoaderShown && loadingState != 'loading-content'}
                  >
                    <styles.Title
                      marginBottom={state == '/resetpassword' ? 'sm' : 'default'}
                      paddingBottom="xxsm"
                      paddingTop={customMobile ? undefined : 'default'}
                      marginTop={customMobile ? undefined : 'lgx'}
                    >
                      {state == '/join' ? (
                        <LocalizedText id="signup.formTitle" />
                      ) : state == '/resetpassword' ? (
                        <LocalizedText id="forgotPassword.formTitle" />
                      ) : (
                        <LocalizedText id="login.formTitle" />
                      )}
                    </styles.Title>
                    <div id="inline-login-container" className={hidden ? 'hidden-lock-wrapper' : ''}></div>
                    {state != '/resetpassword' && (
                      <Flex marginTop="md">
                        {state == '/join' ? (
                          <Flex className="swith-to-login-box">
                            <styles.HaveAccount>
                              <LocalizedText id="signup.alreadyHaveAnAccount" />
                            </styles.HaveAccount>
                            <LinkArrow
                              onClick={e => {
                                setHidden(true);
                                setState('/login');
                                showLock('login', () => {
                                  setHidden(false);
                                });
                                e.preventDefault();
                                return false;
                              }}
                              href={`login`}
                              text="signup.alreadyHaveAnAccount.logIn"
                            />
                          </Flex>
                        ) : (
                          <Flex className="swith-to-signup-box">
                            <styles.HaveAccount>
                              <LocalizedText id="login.dontHaveAnAccount" />
                            </styles.HaveAccount>
                            <LinkArrow
                              onClick={e => {
                                setHidden(true);
                                setState('/join');
                                showLock('signUp', () => {
                                  setHidden(false);
                                });
                                e.preventDefault();
                                return false;
                              }}
                              href={`join`}
                              text="login.dontHaveAnAccount.signUp"
                            />
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </styles.AuthBoxMainContent>
                </styles.AuthBox>
              </Flex>
            </styles.AuttSection>
          </styles.Content>
        </Flex>
      </styles.Container>
    )) || <></>
  );
};

export default JoinLogin;
