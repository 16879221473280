import styled from 'styled-components';
import { TabletAndDown } from '../../components/stylingUtils';

export default {
  Container: styled.div`
    display: flex;
    min-height: 70vh;
    width: 500px;
    margin: 0 auto;

    ${props => TabletAndDown(props)} {
      width: initial;
    }
  `,
  Icon: styled.div`
    flex: 1 0 25%;
  `,
  Text: styled.div`
    flex: 0 1 75%;

    ${props => TabletAndDown(props)} {
      padding: 3rem;
    }
  `
};
