import React from 'react';
import Iframe, { AspectRatio } from 'src/components/Iframe';

type VideoFrameProps = {
  url: string;
  key?: string;
  scale?: string;
  aspectRatio?: AspectRatio;
};

export const VideoFrame = (props: VideoFrameProps) => {
  return (
    <Iframe.Container aspectRatio={props.aspectRatio} scale={props.scale}>
      <iframe frameBorder="0" allowFullScreen key={props.key} src={props.url} data-hj-allow-iframe="true"></iframe>
    </Iframe.Container>
  );
};

export default VideoFrame;
