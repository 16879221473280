import styled from 'styled-components';
import { LaptopAndUp, TabletAndUp, DesktopAndUp, TabletAndDown } from '../../components/stylingUtils';
import Container from '../../components/Container';
import Flex from 'src/components/Flex';
import Text from '../../components/Text';
import { css } from 'styled-components';

export const ImageAndVideoContentBox = css`
  width: 100%;
  display: block;
  margin-bottom: ${props => props.theme.spacing.sm};
  margin-top: 15px;

  ${props => DesktopAndUp(props)} {
    margin-top: 20px;
  }
`;

export default {
  Container: styled.div`
    display: flex;
    background: white;

    > img {
      display: none;

      /* On laptop the image is displayed */
      ${props => LaptopAndUp(props)} {
        display: flex;
        object-fit: cover;
        object-position: bottom;

        justify-content: center;
        overflow: hidden;
        height: 100%;
        width: 43%;
        position: fixed;
      }
    }
  `,
  ImageContainer: styled.div`
    ${props => LaptopAndUp(props)} {
      display: flex;
      object-fit: cover;
      object-position: bottom;

      justify-content: center;
      overflow: hidden;
      height: 100%;
      flex: 1.5;
    }
  `,

  Content: styled.div`
    width: 100%;

    /* Tablet and beyond have a different padding */
    ${props => TabletAndUp(props)} {
      flex: 2;
      ${Container} {
        margin: ${props => props.theme.spacing.lgxx} auto;
      }
    }
  `,
  ContentContainer: styled.div`
    ${props => TabletAndUp(props)} {
      padding: 0 ${props => props.theme.spacing.xxlg};
    }
    ${props => DesktopAndUp(props)} {
      padding: 0 ${props => props.theme.spacing.xxxxlg};
    }

    max-width: 1000px;
    margin: 0 auto;
  `,
  InfoContainer: styled(Flex)`
    ${props => DesktopAndUp(props)} {
      min-height: 1000px;
    }

    width: 42% !important;

    @media (max-width: 1395px) {
      width: 50% !important;
    }

    @media (max-width: 1146px) {
      width: 100% !important;
    }

    background-color: ${props => props.theme.colors.background};
  `,
  InfoContainerContent: styled(Flex)`
    /* Custom mobile treshold */
    width: 100% !important;

    /* Custom mobile treshold */
    @media (max-width: 1146px) {
      width: 584px !important;
    }

    @media (max-width: 800px) {
      width: 100% !important;
    }
  `,
  AuthBox: styled(Flex)`
    width: 584px !important;

    /* Custom mobile treshold */
    @media (max-width: 1400px) and (min-width: 1146px) {
      width: 420px !important;
    }

    @media (max-width: 800px) {
      width: 100% !important;
    }
  `,
  IconCircle: styled(Flex)<{ scale: number }>`
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14281);
    width: 177.07px;
    height: 177.07px;
    border-radius: 150px;
    position: absolute;
    margin-left: -85px;

    /* Custom mobile treshold */
    @media (max-width: 1646px)  {
      transform: scale(0.71);
    }

    @media (max-width: 1146px)  {
      margin-left: auto;
      margin-right: auto;
      margin-top: -91px;
      left: 0;
      right: 0;
    }

    img {
      width: 74px;
      height: 74px;
      transform: scale(${props => props.scale});
    }
  }
  `,
  AuthBoxMainContent: styled.div<{ visible: boolean }>`
    ${props => (!props.visible ? 'display:none;' : '')};
  `,

  AuttSection: styled(Flex)`
    background-color: white;
  `,
  TopBar: styled(Flex)`
    background-color: white;

    /* Custom mobile treshold */
    @media (max-width: 1146px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 76px;
      filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.0829873));
      z-index: 1100;
    }
  `,
  Title: styled(Text.H2Responsive)`
    color: ${props => props.theme.colors.text.onWhite};
  `,
  InfoTitle: styled(Text.H2)`
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
    color: ${props => props.theme.colors.text.onWhite};
    padding-bottom: ${props => props.theme.spacing.sm};
    display: block;
    ${props => TabletAndDown(props)} {
      font-size: 28px;
    }
  `,
  InfoSubTitle: styled(Text.H4)`
    font-size: 18px;
    line-height: ${props => props.theme.spacing.md};
    font-weight: normal;
    padding-bottom: ${props => props.theme.spacing.lg};
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    display: block;
  `,
  InfoListText: styled(Flex)`
    font-size: 18px;
    line-height: ${props => props.theme.spacing.md};
    font-weight: normal;
    padding-bottom: ${props => props.theme.spacing.default};
    color: ${props => props.theme.colors.text.onWhiteSecondary};

    img {
      width: 28px;
      padding-right: 17px;
    }

    ${props => TabletAndDown(props)} {
      font-size: 16px;
      line-height: 22px;
      padding-bottom: 17px;
      img {
        width: ${props => props.theme.spacing.default};
      }
    }
  `,
  InfoImage: styled.img`
    ${ImageAndVideoContentBox}
  `,
  InfoVideo: styled.div`
    ${ImageAndVideoContentBox}
  `,
  InfoInlineSmallText: styled.span`
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    color: ${props => props.theme.colors.text.onWhiteSecondary};
  `,
  InfoInlineSmallLink: styled.a`
    font-size: 14px;
    line-height: 22px;
    text-decoration: underline;
    font-weight: normal;
    padding-left: ${props => props.theme.spacing.xxsm};
    color: ${props => props.theme.colors.text.onWhiteSecondary};
  `,
  InfoShowMoreText: styled(Text.H4)`
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;
    text-decoration: underline;
    color: ${props => props.theme.colors.text.onWhiteSecondary};
    margin-top: -26px;
    img {
      margin-left: 6px;
      margin-top: 0px;
      opacity: 0.9;
    }
  `,
  HaveAccount: styled(Text.H5Responsive)`
    font-size: 16px;
  `,
  LogoWrapper: styled.div`
    width: 100%;
  `,
  ActionLink: styled.a`
    text-decoration: none;
  `,
  Logo: styled.div`
    display: inline-flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    right: 64px;
    top: 64px;

    img {
      height: ${props => props.theme.spacing.lg};

      display: inline-flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    @media (max-width: 1146px) {
      top: 17px;
      right: 12px;
      img {
        height: 40.3px;
      }
    }
  `
};
