// Logic documentation: https://dev.azure.com/S-GEDevops/DigitaleProdukte/_wiki/wikis/Digital%20offerings/131/1.5.1.2-Sign-up-screen

/* const defaultText = `title|Service context relevant tagline on 2 lines
sub-title|Clearly convey the value of service. Maxiumum 1 line.
point|Clearly convey the value explanation on maxiumum 2 lines
point|Another advantage highlight on maxium 2 lines
point|Yet another advantage highlight on maxium 2 lines
image|/static/img/login-join-img-default.jpg
inline-small-text|...or discover all benefits
inline-small-link|on this webpage|https://www.google.pl
`; */

const DetaultServiceContent = {
  icon: {
    src: '/static/icons/country-comparison.svg',
    scale: 2.7
  },
  translationId: 'signupLogin.serviceContent.default'
};

const EventsOverview = {
  icon: {
    src: '/static/icons/my-events.svg',
    scale: 2.7
  },
  translationId: 'signupLogin.serviceContent.eventsOverview'
};

const BusinessOpportunities = {
  icon: {
    src: '/static/icons/bose.svg',
    scale: 1.9
  },
  translationId: 'signupLogin.serviceContent.businessOpportunities'
};

export const servicesContent = {
  Default: DetaultServiceContent,
  CockpitDashboard: DetaultServiceContent,
  CompareCountries: DetaultServiceContent,
  ExportGuide: {
    icon: {
      src: '/static/icons/export-guide.svg',
      scale: 1.9
    },
    translationId: 'signupLogin.serviceContent.exportGuide'
  },
  EmployeePosting: {
    icon: {
      src: '/static/icons/employee-posting.svg',
      scale: 2.0
    },
    translationId: 'signupLogin.serviceContent.employeePosting'
  },
  EventsOverview,
  MyEvents: EventsOverview,
  SpecificEvent: EventsOverview,
  BusinessOpportunities,
  SpecificBusinessOpp: BusinessOpportunities,
  CountryGuides: DetaultServiceContent, //to be checked
  ArticlesOverview: DetaultServiceContent,
  SpecificArticle: DetaultServiceContent,
  MyProfile: DetaultServiceContent,
  Onboarding: DetaultServiceContent
};

// Logic documentation: https://dev.azure.com/S-GEDevops/DigitaleProdukte/_wiki/wikis/Digital%20offerings/131/1.5.1.2-Sign-up-screen
export const redirectUrl2serviceName = (redirectUrl?: string, locale?: string) => {
  const redirectUrl_query = (redirectUrl || '/').split('?');
  const redirectUrl_ = redirectUrl_query[0].replace('/' + locale + '/', '/');

  //(Default), Cockpit Dashboard, Compare Countries
  if (redirectUrl_ == '/' || redirectUrl_ == '/cockpit' || redirectUrl_ == '/explore-markets') {
    return 'Default';
  }

  //Export Guide
  if (redirectUrl_ == '/export-guide') {
    return 'ExportGuide';
  }

  //Employee Posting
  if (redirectUrl_.includes('/posting-of-employees')) {
    return 'EmployeePosting';
  }

  //Events Overview, My Events, Specific Event
  if (redirectUrl_ == '/events' || redirectUrl_ == '/my-events' || redirectUrl_.includes('/event/')) {
    return 'EventsOverview';
  }

  //Business Opportunities, Specific Business Opp
  if (redirectUrl_ == '/business-opportunities' || redirectUrl_.includes('/business-opportunities/')) {
    return 'BusinessOpportunities';
  }

  if (redirectUrl_.includes('/markets/')) {
    return 'CountryGuides';
  }

  //Articles Overview, Specific Article, My Profile
  if (redirectUrl_ == '/articles' || redirectUrl_.includes('/article/') || redirectUrl_ == '/profile') {
    return 'Default';
  }

  return 'WrongRedirectUrl';
};
